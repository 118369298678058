<script>
import Svc from "@/services/payments-service";
import Repo from "@/models/PaymentRepo"
import FormErrors from '@/util/form-errors';
import errorsMixin from '@/mixins/errors.mixin';
import { PaymentCustom, PAYMENT_STATUS, PaymentLog } from '@/models/paymentModel';
import PaymentsValidator from "@/models/PaymentValidator"
import featureFlagsMixin from '@/mixins/featureFlags.mixin'

export default {
  render() {
    return this.$scopedSlots.default({
      list: this.list,
      listLoading: this.listLoading,
      loadData: this.loadData,
      showing: this.showing,
      toggle: this.toggle,
      formErrors: this.formErrors,
      setNewEditor: this.setNewEditor,
      create: this.validateCreation,
      processing: this.processing,
      ID: this.ID,
      logs: this.logs,
      updateCanceledLog: this.updateCanceledLog,
      recomendedPayment: this.recomendedPayment,
      candidate: this.candidate,
      updateCandidateRefunds: this.updateCandidateRefunds
    })
  },

  mixins: [errorsMixin, featureFlagsMixin],

  props: {
    getLogs: Function,
    model: Object
  },

  data: () => ({
    showing: false,
    svc: new Svc(
      new Repo()
    ),
    list: [],
    logs: [],
    listLoading: false,
    ID: null,
    candidate: {},
    formErrors: new FormErrors(),
    editorData: new PaymentCustom(),
    processing: false,
    recomendedPayment: ''
  }),

  mounted() {
    // this.loadData()
  },

  methods: {
    async loadData(params = {cache: false}) {
      this.listLoading = true
      let [list] = await Promise.all([this.loadList({cache: params.cache})])
      this.list = list
      this.listLoading = false
    },

    async loadList(params = {cache: false}) {
      try {
        return await this.svc.listPaymentAmount(params, this.ID)
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    },

    validateCreation(data) {
      if (!this.candidate.candidateEmail)
        return this.$notify({text: 'No candidate email', type: 'error'})
      if (!data.systemPayment) {
        if (!this.isValid(data))
          return this.$notify({text: 'invalid field', type: 'error'})
      }
      this.create(data)
    },

    isValid(payment) {
      let paymentValidator = new PaymentsValidator(payment, this.formErrors)
      return paymentValidator.validate()
    },

    async create(data) {
      try {
        this.processing = true
        let res = await this.svc.create(this.ID, data)
        this.toggle()
        let newLog = new PaymentLog(res)
        this.logs.unshift(newLog)
        this.$emit('create', {ID: this.ID, log: newLog})
        this.$notify({text: 'Payment created', type: 'success'})
        return res
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
      finally {
        this.processing = false
      }
    },

    saveCandidateAndOpen(data) {
      this.ID = data.ID,
      this.candidate = data
      this.logs = data.payments
      this.recomendedPayment = data.recommended_payment
      this.open()
    },

    open() {
      this.loadData()
      this.toggle()
    },

    setNewEditor() {
      this.editorData = new PaymentCustom({
        label: 'Custom'
      })
    },

    toggle() {
      this.showing = !this.showing
    },

    loadLogs() {
      this.logs = this.getLogs()
    },

    updateCanceledLog(ID) {
      let logIndex = this.logs.findIndex(log => log.ID === ID)
      if(logIndex > -1) this.logs[logIndex].status = PAYMENT_STATUS.CANCELED
      this.$emit('canceled', {ID})
    },

    updateCandidateRefunds({ID, data}) {
      let logIndex = this.logs.findIndex(log => log.ID === ID)
      if(logIndex < 0) return

      let refund = new PaymentLog(data)
      // this.logs[logIndex].refunds.unshift(refund)
      this.candidate.payments[logIndex].refunds.unshift(refund)
    }
  },
}
</script>
