export default class PaymentsValidator {
  constructor(payment, validator) {
    this.payment = payment
    this.validator = validator
  }

  validate () {
    this.validator.resetFields()
    this._validateAmount()
    this._validateLabel()
    this._validateSubject()
    this._validateContent()
    return this.validator.isValid()
  }

  _validateAmount() {
    this.validator.validField()
    if( !(/^[+-]?\d+(\.\d+)?$/.test(this.payment.value)) ) 
      this.validator.invalidField('value', 'Amount must be in correct format(20 or 20.30)')
    if(!this._hasValue(this.payment.value)) 
      this.validator.invalidField('value', 'Field required')
  }

  _validateLabel() {
    this.validator.validField()
    if(!this._hasValue(this.payment.label)) 
      this.validator.invalidField('label', 'Field required')
    if(this.payment.label.length > 30) 
      this.validator.invalidField('label', 'Label should contain 30 characters or less')
  }

  _validateSubject() {
    if(!this._hasValue(this.payment.subject)) 
      this.validator.invalidField('subject', 'Field required')
  }

  _validateContent() {
    if(!this._hasValue(this.payment.content)) 
      this.validator.invalidField('content', 'Field required')
  }

  _hasValue(value) {
    if(!value || !value.length) return false
    return true
  }
}
